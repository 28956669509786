import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { useSiteConfig } from '@services/site-config.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  titleService = inject(Title);
  siteConfig = useSiteConfig();
  siteName = computed(() => {
    const name = this.siteConfig()?.seo?.metaTitle || this.siteConfig()?.displayName;
    return name ? name + ' - ' : '';
  });
  title = signal('');

  constructor() {
    effect(() => {
      const title = this.title();
      if (this.title()) {
        this.titleService.setTitle(this.siteName() + title);
      }
    });
  }

  setTitle(title: string) {
    this.title.set(title);
  }
}
